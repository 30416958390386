<template>
    <div>
        <h1>Оферта</h1>
        <div>Текст оферты</div>
    </div>
</template>

<script>
export default {
    name: "Offer"
}
</script>